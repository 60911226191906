import { Component } from "@angular/core";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  template: `
    <span class="created-by"
      >Made with ♥ by Nicolas at
      <b><a href="https://k30.no" target="_blank">K30</a></b> 2019</span
    >
    <div class="socials">
      <a
        href="https://facebook.com/myndlist"
        target="_blank"
        class="ion ion-social-facebook"
      ></a>
    </div>
  `
})
export class FooterComponent {}
