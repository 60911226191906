import { AuthGuardService } from "./auth/auth-guard.service";
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NbAuthModule,
  NbDummyAuthStrategy,
  NbPasswordAuthStrategy
} from "@nebular/auth";
import { NbSecurityModule, NbRoleProvider } from "@nebular/security";
import { of as observableOf } from "rxjs";

import { throwIfAlreadyLoaded } from "./module-import-guard";
import { AnalyticsService } from "./utils";
// import { UserData } from "./data/users";
// import { UserService } from "./mock/users.service";
import { environment } from "../../environments/environment";

const {
  firebase: { apiKey }
} = environment;

const socialLinks = [];

const DATA_SERVICES = [];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf("guest");
  }
}

export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: "email",
        token: {
          key: "idToken"
        },
        baseEndpoint: "https://identitytoolkit.googleapis.com/v1/",
        login: {
          endpoint: `accounts:signInWithPassword?key=${apiKey}`,
          redirect: {
            success: "/",
            failure: null
          }
        }
      })
    ],
    forms: {
      login: {
        socialLinks: socialLinks,
        rememberMe: true
      }
    }
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: "*"
      },
      user: {
        parent: "guest",
        create: "*",
        edit: "*",
        remove: "*"
      }
    }
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider
  },
  AnalyticsService,
  AuthGuardService
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS]
    };
  }
}
