import { of as observableOf, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { User } from "../data/users";

@Injectable({
  providedIn: "root"
})
export class UserService {
  private users: User[] = [
    {
      firstName: "Aðalheiður Dögg",
      lastName: "Reynisdóttir",
      personalNumber: "190796",
      gender: "female",
      address: "Borgarás 10",
      postCode: 210,
      city: "Garðarbær",
      email: "adalheidur96@gmail.com",
      mobilePhone: "565 6528",
      homePhone: "691 6307"
    },
    {
      firstName: "Nicolas",
      lastName: "Cassis",
      personalNumber: "170884",
      gender: "male",
      address: "",
      postCode: 200,
      city: "Kópavogur",
      email: "nicolas.cassis@gmail.com",
      mobilePhone: "40382477",
      homePhone: null
    }
  ];

  getUser(userEmail: string): Observable<User> {
    const user = this.users.find(user => user.email === userEmail);
    return observableOf(user);
  }
}
