import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { environment } from "./../environments/environment";
import { ApolloLink } from "apollo-link";

const {
  graphql: { uri, secret }
} = environment;

export function createApollo(httpLink: HttpLink) {
  const http = httpLink.create({ uri });
  const authLink = new ApolloLink((operation, forward) => {
    if (secret) {
      operation.setContext({
        headers: {
          "content-type": "application/json",
          "X-Hasura-Admin-Secret": secret
        }
      });

      return forward(operation);
    }

    throw new Error("Apollo could not find graphql secret");
  });

  return {
    link: authLink.concat(http),
    cache: new InMemoryCache()
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}
